.Footer {
    width: 100%;
    min-height: 40vh;
    background: linear-gradient(145deg, #1e2024, #23272b);
    position: relative;
    text-align: center;
    padding-top: 50px;
}
.rights {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: #c4cfde;
    margin-top: 60px;
}
.footer-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 300px;
    position: relative;
    /* padding: 100px 100px 50px 100px; */
}
.footer-container > div {
    max-height: 80%;
    text-align: left;
}
.footer-nav li {
    
    margin-bottom: 20px;
    font-size: 18px;
    color: #c4cfde;
}
.footer-nav li a:hover {
    color: #f9004d;
    cursor: pointer;
}
.footer-info > div {
    width: 100%;
    /* text-align: left; */
    margin-bottom: 20px;
    color: #c4cfde;
    font-size: 18px;
}
.footer-icon {
    color: #f9004d;
}
.footer-image {
    text-align: center;
}
.footer-image span {
    color: #c4cfde;
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-bottom: 30px;
}
.footer-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto; 
    margin-left: 35px;
}
@media (max-width: 968px) {
    .footer-container {
        gap: 100px;
        text-align: center;
    }
    .footer-image img {
        margin-left: 0;
    }
}
@media (max-width: 768px) {
    .footer-container {
        gap: 0px;
        text-align: center;
        padding: 20px 60px 50px 60px;
    }
    .footer-container > div {
        flex-basis: 80%;
        text-align: center;
    }
    .footer-info {
        margin-bottom: 50px;
    }
}