:root{
    --red: #FE4343;
    --white: #f1f7fa;
    --light-blue: #00a2ff;
    --blue: #005485;
    --gray: #d8d8d8;
}

.Navbar {
    width: 100%;
    height: 10vh;
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.Navbar p {
    color: #f9004d;
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
}
.Navbar > .logo{
    border: 2px solid #f9004d;
    padding: 0 0 10px 6px;
    border-top-style: none;
    border-right-style: none;
    border-bottom-left-radius: 10px;
    line-height: 0.7;
    margin-top: 5px;
}
.Navbar ul li,
.Navbar ul li a {
    list-style: none;
    display: inline-block;
    margin: auto 20px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    /* font-family: lato, sans serif; */
}
.Navbar li a:hover {
    color: #f9004d;
}
/* .Navbar button {
    font-size: 14px;
    font-weight: 400;
    border: none;
    
    
    color: #f9004d;
    background: linear-gradient(145deg, #1e2024, #23272b);;
    border-radius: 6px;
    letter-spacing: 3;
    padding: 0.6rem 0.9rem 0.6rem 0.9rem;
    cursor: pointer;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    transition: 0.4s;
    width: 100px;
    height: 50px;
} */


.header > .m-navBar-wrapper{
    display: none;

    width: 100%;
    height: 70px;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    transition: height .35s ease;

    /* box-shadow: 0 2px 6px 2px #38381c; */
    box-shadow: 0 1px 6px 2px #8e8e8380;

    background-color: #23272b;
}
.header > .m-navBar-wrapper > .m-navBar{
    position: relative;
    background-color: #f9004d; /* var(--light-blue) */
    height: 244px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: height .35s ease,
                background-color .35s ease;
}
.header > .m-navBar-wrapper[data-expanded=true]{
    height: 244px;
}
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar {
    background-color: #23272b;
}
.header > .m-navBar-wrapper > .m-navBar > .top{
    width: calc(100% - 40px);
    /* padding-right: 20px; */
    padding-left: 30px; /* 20px */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.header > .m-navBar-wrapper > .m-navBar > .top > .logo{
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
}
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar > .top > .logo{
    margin-inline: auto;
}
.header > .m-navBar-wrapper > .m-navBar > .top > button{
    border: none;
    background: transparent;
    /* position: absolute; */
    height: 30px;
    aspect-ratio: 1;
    /* top: 25px;
    right: min(30px, 5vw); */
    padding: 0;
}
.header > .m-navBar-wrapper > .m-navBar > .top > button path{
    stroke-dasharray: 6 3 6 3 6 25;
    stroke-dashoffset: 0;
    stroke: var(--white);
    transition: stroke .5s ease, stroke-dasharray .5s ease, stroke-dashoffset .5s ease;
}
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar > .top > button path{
    stroke-dasharray: 6 9 6 28; /* 26 49 7 7 10 */
    stroke-dashoffset: 24; /* 50 */
    stroke: var(--white);
}
.header > .m-navBar-wrapper > .m-navBar > .middle{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.header > .m-navBar-wrapper > .m-navBar a{
    text-decoration: none;
    color: var(--gray);
    text-align: center;
}
.header > .m-navBar-wrapper > .m-navBar a.selected{
    border-bottom-style: solid;
    border-color: #f9004d;
    border-width: 4px;
}

.header > .m-navBar-wrapper.transparent:not([data-expanded=true]) > .m-navBar{
    background-color: transparent;
}
.header > .m-navBar-wrapper.transparent > .m-navBar > .top > .logo{
    color: #f9004d;
}
.header > .m-navBar-wrapper.transparent > .m-navBar > .top > button path{
    stroke: #f9004d;
}

.header > .m-navBar-wrapper.transparent[data-expanded=true] > .m-navBar > .top > .logo{
    color: var(--white);
}
.header > .m-navBar-wrapper.transparent[data-expanded=true] > .m-navBar > .top > button path{
    stroke: var(--white);
}
@media screen and (max-width: 767px) {
    .header > .m-navBar-wrapper{
        display: block;
    }
    .Navbar {
        display: none;
    }
}