.Left {
    flex-basis: 38%;
    background-color: transparent;
    min-height: 85vh;

}
.user-info {
    padding: 35px;
    background-color: #212428;
    width: 90%;
    height: 50vh;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    margin: 0 0 30px 0;
}
.user {
    position: relative;
    z-index: 1;
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
    background-color: #f9004d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user::after{
    content: "";
    position: absolute;
    transition: var(--transition);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    background: #f9004d;
    opacity: 0.2;
    z-index: -2;
    transform: translate(-50%, -50%);
}
.user .icon {
    color: white;
    font-size: 18px;
}
.user-info h2 {
    color: #ffffff;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 11px;
    /* font-family: var(--font-secondary); */
}
.user-info h2 span {
    color: #f9004d;
}
.Left p {
    font-size: 18px;
    line-height: 24px;
    color: #ebebeb;
    text-align: justify;
    margin-bottom: 20px;
}
.user-info li {
    list-style: none;
    color: #ebebeb;
    margin-top: 5px;
    font-size: 15px;
}
.user-info .info-icon {
    color: #f9004d;
    margin-right: 10px;
    
}
.user-cv {
    padding: 35px;
    padding-top: 45px;
    background-color: #212428;
    width: 90%;
    height: 30vh;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    margin: 30px 0 0px 0;
}
.user-cv button {
    font-size: 15px;
    font-weight: 400;
    border: none;
    color: #f9004d;
    background: linear-gradient(145deg, #1e2024, #23272b);;
    border-radius: 6px;
    letter-spacing: 3;
    padding: 0.6rem 0.9rem 0.6rem 0.9rem;
    cursor: pointer;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    transition: 0.4s;
    width: 150px;
    height: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.user-cv a {
    color: #f9004d;
}
@media (max-width: 1000px) {
  .Left {
    width: 100%;
    flex-basis: 90%;
    margin-bottom: 50px;
  }
  .user-info {
    width: 100%;
    height: auto;
    padding: 35px 20px 35px 20px;
  }
  .user-cv {
    height: auto;
    text-align: center;
    width: 100%;
  }
  .user-cv button {
    margin-right: 0;
    width: 80%;
  }
  .user  {
    margin-bottom: 35px;
  }
}