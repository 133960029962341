.education-container {
    width: 100%;
    height: auto;
    border-left: 2px solid white;
    padding-left: 26px;
    margin-top: 50px;
}
.eduction-item {
    width: 280px;
    height: 100px;
    background-color: white;
    border-left: 6px solid #f9004d;
    border-radius: 10px;
    padding: 15px;
    line-height: 1.5;
    position: relative;
    margin-bottom: 40px;
}
.eduction-item h5 {
    font-size: 14px;
}
.eduction-item span {
    color: gray;
}
.list-div  {
    position: absolute;
    top: 20px;
    left: -31px;
    width: 26px;
    height: 2px;
    background-color: #f9004d;
}
.list-span  {
    position: absolute;
    top: 16px;
    left: -37px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f9004d;
}