.Resume {
    width: 100%;
    min-height: 90vh;
    padding: 100px;
}
.Resume .bar {
    width: 100px;
    height: 2px;
    margin-top: 10px;
    background-color: #f9004d;
}
.Resume h2 {
    color: #fff;
}
.Resume-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}
.Experience , .Education {
    flex-basis: 50%;
    width: 100%;
    margin-top: 50px;
}
.Resume h3 {
    color: #fff;
}

@media (max-width: 768px) {
    .Resume {
        padding-left: 30px;
    }
}