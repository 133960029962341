.ProjectList {
    width: 100%;
    min-height: 90vh;
    padding: 100px 20px 50px 20px;
    text-align: center;
    position: relative;
}
.ProjectList .bar {
    width: 230px;
    height: 2px;
    margin-top: 10px;
    background-color: #f9004d;
    position: absolute;
    left: 42.5%;
}
.ProjectList h2 {
    color: #fff;
    font-size: 40px;
}
.p-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 20px 0;
    gap: 40px;
    flex-wrap: wrap;
}

/**/

