.Right {
    flex-basis: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items:center ;
    background-color: #212428;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    width: 100%;
    min-height: 85vh;
}
.cover-list {
    width: 90%;
    height: 90%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items:center ;
    overflow: hidden;
    /* padding: 10px; */
    /* position: relative; */
}
.cover-list img {
    height: 26rem;
    width: 24rem;
    border-radius: 10px;
    display: block;
    /* position: absolute; */
    /* opacity: 0; */
    /* transition: opacity 1s ease-in-out; */
}
.cover-list img.active {
    opacity: 1;
  }

@media (max-width: 1000px) {
    .cover-list img {
        height: 22rem;
        width: 20rem;
        
    }
    .Right {
        min-height: 60vh;
    }
}