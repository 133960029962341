.Section {
    width: 100%;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #1e2024, #23272b);
    padding: 40px 0 30px 0;
    flex-wrap: wrap;
}
@media (max-width: 1000px) {
    .Section {
        margin-top: 15vh;
    }
}