.ProjectItem {
    width: 23rem;
    min-height: 200px;
    background-color: #212428;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    /* display: flex;
    flex-wrap: wrap; */
    padding-bottom: 0;
    border-radius: 10px;
}
.Project-header {
    width: 100%;
    /* padding: 10px; */
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Project-header img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px 10px 0 0;
}
.Project-decription {
    width: 100%;
    padding: 20px;
    /* display: flex;
    flex-wrap: wrap; */
    height: 70%;
}
.Project-content {
    width: 100%;
    text-align: left;
    color: #c4cfde;
    /* padding-right: 15px; */
    height: 80%;
}
.Project-content h3 {
    font-size: 25px;
    margin-bottom: 10px;
}
.Project-content p {
    text-align: justify;
    margin-bottom: 20px;
}
#stack {
    color: #f9004d;
    margin-bottom: 40px;
}
.Project-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Project-btn button {
    font-size: 15px;
    font-weight: 400;
    margin-right: -1px;
    border: none;
    color: #f9004d;
    background: linear-gradient(145deg, #1e2024, #23272b);
    border-radius: 6px;
    letter-spacing: 3;
    padding: 0.6rem 0.9rem 0.6rem 0.9rem;
    cursor: pointer;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    transition: 0.4s;
    width: 100%;
    height: 50px;
    /* margin-right: 15px; */
    /* margin-bottom: 15px; */
}
.Project-btn button a {
    color: #f9004d;
}