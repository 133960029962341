.Contact {
    width: 100%;
    min-height: 80vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-form {
    width: 50%;
    padding: 1rem;
    padding-left: 1.5rem;
}
.contact-form h2 {
    color: white;
}
.contact-form p {
    margin: 10px 0 10px 0;
    color: white;
}
.contact-form input , .contact-form textarea {
    width: 95%;
    margin: 0.5rem 0 1rem 0;
    padding: 0.5rem;
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    resize: none;
}
.contact-form input {
    height: 50px;
}
.contact-form input:focus , textarea:focus {
    outline: none;
}
.contact-form button {
    font-size: 15px;
    font-weight: 400;
    border: 1px solid #f9004d;
    color: #fff;
    background: linear-gradient(145deg, #1e2024, #23272b);
    border-radius: 6px;
    letter-spacing: 3px; /* Added "px" after the value */
    padding: 0.6rem 0.9rem; /* Adjusted padding */
    cursor: pointer;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    transition: 0.4s;
    width: 150px;
    height: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.contact-form button:hover {
    background: none;
    background-color: #f9004d;
}

@media (max-width: 768px) {
   .contact-form {
        width: 90%;
        height: 50%;
    }
    .contact-form {
        padding: 0.5rem;
    }
}